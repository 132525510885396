import React, { useState } from "react"
import PropTypes from "prop-types"
import Typewriter from "typewriter-effect"

import "react-toggle/style.css"

const Header = ({ siteTitle }) => {
  const [isLoaded, setIsLoaded] = useState(
    typeof window !== "undefined"
      ? sessionStorage.getItem("isLoaded") || false
      : false
  )

  return (
    <header>
      <div style={{ display: "inline-flex" }}>
        <h1>{">"}</h1>
        <h1>
          {isLoaded ? (
            "Akshay Bhandari"
          ) : (
            <Typewriter
              style={{ marginTop: 0, paddingTop: 0 }}
              options={{
                deleteSpeed: "natural",
              }}
              onInit={typewriter => {
                const loop = () => {
                  typewriter
                    .typeString("Akshay Bhandari")
                    .pauseFor(250)
                    .deleteAll()
                    .typeString("DevOps Enthusiast")
                    .pauseFor(250)
                    .deleteAll()
                    .typeString("Backend Developer")
                    .pauseFor(250)
                    .deleteAll()
                    .typeString("Problem Solver")
                    .pauseFor(250)
                    .deleteAll()
                    .typeString("Cloud Engineer")
                    .pauseFor(250)
                    .deleteAll()
                    .typeString("Software Developer")
                    .pauseFor(250)
                    .deleteAll()
                    .typeString("Docker & Kubernetes Expert")
                    .pauseFor(250)
                    .deleteAll()
                    .typeString("CI/CD Specialist")
                    .pauseFor(250)
                    .deleteAll()
                    .callFunction(() => {
                      setTimeout(loop, 0);
                    })
                };

                typewriter.start(); // Start the typewriter
                loop(); // Initial call to start the loop
              }}
            />
          )}
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
